<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable && $can('create_item_pengadaan')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3 mr-3" @click="modalAddActive = true">Tambah</vs-button>
            <vs-button v-if="!selectable" color="primary" type="line" icon-pack="feather" icon="icon-check-square" class="px-3" @click="modalSetPerProyekActive = true">Set Per Proyek</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama">Nama</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="group1">Group 1</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="group2">Group 2</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode">COA</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode">Kode</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama">Satuan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap" v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer" v-if="$canAny(['update_item_pengadaan', 'delete_item_pengadaan'])">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="showModalEdit(item)"><span class="whitespace-no-wrap" v-if="$can('update_item_pengadaan')">Edit</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger" v-if="$can('delete_item_pengadaan')">Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama">{{ item.nama }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.group1">{{ item.group1 }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.group2">{{ item.group2 }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.nama_coa">{{ item.nama_coa }} ({{ item.kode_coa }})</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.kode">{{ item.kode }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.satuan">{{ item.satuan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.keterangan">{{ item.keterangan }}</vs-td>
            <vs-td class="whitespace-no-wrap" :data="item.created_at">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <ItemPengadaanAdd v-if="!selectable" :isActive.sync="modalAddActive" @success="getData"/>
    <ItemPengadaanEdit v-if="!selectable" :isActive.sync="modalEditActive" @success="getData" :item="modalEditItem"/>
    <ItemPengadaanSetPerProyek :isActive.sync="modalSetPerProyekActive"/>
  </div>
</template>

<script>
import ItemPengadaanRepository from '@/repositories/master/item-pengadaan-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'

export default {
  name: 'ItemPengadaan',
  components: {
    ItemPengadaanEdit: () => import('@/views/pages/master/item-pengadaan/ItemPengadaanEdit'),
    ItemPengadaanAdd: () => import('@/views/pages/master/item-pengadaan/ItemPengadaanAdd'),
    ItemPengadaanSetPerProyek: () => import('@/views/pages/master/item-pengadaan/ItemPengadaanSetPerProyek'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      modalAddActive: false,
      modalEditActive: false,
      modalSetPerProyekActive: false,
      modalEditItem: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      ItemPengadaanRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      ItemPengadaanRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEditItem = item
      this.modalEditActive = true
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
